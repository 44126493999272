import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import RuleEditorHelp from './RuleEditorHelp';

import DataSourceDropDown from '../../components/DataSource/DropDown';
import DataSourceMessage from '../../components/DataSource/DataSourceMessage';
import RuleScopeDisplay from '../../components/DataSource/RuleScopeDisplay';
import SaveButton from '../../components/DataSource/SaveButton';
import InfoCircle from '../../components/Icons/InfoCircle';
import NewTab from '../../components/Icons/NewTab';
import ProjectDropDown from '../../components/ProjectDropdown';

import LoadingWrapper from '../../components/LoadingWrapper';
import { errorToast, successToast } from '../../components/Toasty';

import { SaveStatus } from '../../enums/SaveStatus';

import DataSourceService from '../../services/DataSourceService';
import DataService from '../../services/DataService';

import ProjectList from '../../types/Dashboard/ProjectList';
import IDataSourceResponse from '../../types/Data/DataSourcesResponse'
import { CellValidation, DataSourceRules, Permissions } from '../../types/DataSource/DataSourceRules';
import DataSourceRulesDetail from '../../types/DataSource/DataSourceRulesDetail';
import DataSourceEmployeeInfo from '../../types/Employee/DataSourceEmployeeInfo';
import PermissionDisplay from '../../components/DataSource/PermissionsDisplay';
import CellValidationDisplay from '../../components/DataSource/CellValidationDisplay';
import ListItem from '../../types/ListItem';

import { QuantaMasterJobCodeList } from '../../constants/QuantaMasterJobCodeList';
import { BlattnerPortalPage } from '../../enums/BlattnerPortalPage';
import { DataSourceHistoryType } from '../../enums/DataSourceHistoryType';

/**
 * It would be nice to pin down an outside (of the code) resource
 *   to contain this list so we can fetch it an not have to worry about
 *   being the maintainers of this "master data"
 * @returns array of ListItems
 */
const generateJobCodeMasterList = (): ListItem[] => {
    const completeJobCodes: ListItem[] = [];
    for (const key in QuantaMasterJobCodeList) {
        completeJobCodes.push({
            value: key,
            label: `${key} - ${QuantaMasterJobCodeList[key as keyof typeof QuantaMasterJobCodeList]}`,
        })
    }
    return completeJobCodes;
}

/**
 * setup a generic empty data source rule set
 * @returns an empty DataSourceRules
 */
const generateEmptyRules = (): DataSourceRules => {
    const emptyPermissions: Permissions = {
        users: [],
        jobCodes: [],
        adGroups: [],
        projects: [],
    }

    const canEditRules: Permissions = {
        ...emptyPermissions,
        jobCodes: ['BIT354', 'BIT306', 'BIT406', 'BIT357', 'BIT307'],
    }

    const cellValidations = [{ column: 0, regex: '^\\d+$' }];

    const emptyRules: DataSourceRules = {
        canEditRules,
        cellValidations,
        editability: emptyPermissions,
        visibility: emptyPermissions
    }

    return emptyRules;
}

type RuleEditorComponentProps = {
    userData: DataSourceEmployeeInfo;
    projectList: ProjectList;
    masterEmployeeList: ListItem[];
};

const RuleEditorComponent = ({ userData, projectList, masterEmployeeList }: RuleEditorComponentProps) => {

    const [ruleProjectList, setRuleProjectList] = useState<ProjectList>();
    const [selectedProject, setSelectedProject] = useState<string>('');
    const [selectedDataSource, setSelectedDataSource] = useState<string>('');
    const [selectedDataSoureceName, setSelectedDataSourceName] = useState<string>('');

    const [dataSourceHeaders, setHeaders] = useState<IDataSourceResponse>();
    const [headersLoading, setHeadersLoading] = useState<boolean>(false);

    const [rulesDetails, setRulesDetails] = useState<DataSourceRulesDetail>();
    const [currentProjectSpecificValue, setCurrentProjectSpecificValue] = useState<boolean>();
    const [dataSourceRules, setDataSourceRules] = useState<DataSourceRules>(generateEmptyRules());
    const [initialDataSourceRules, setInitialDataSourceRules] = useState<DataSourceRules>();
    const [rulesLoading, setRulesLoading] = useState<boolean>(false);

    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Saved);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editable, setEditable] = useState<boolean>(false);

    /**
     * this is used to catch when the project list changes and append the two
     *   projects to the list that we want available for all the data edits
     */
    useEffect(() => {
        const list = structuredClone(projectList);

        /**
         * These are used to catch the case in production where the list of projects
         *   that is presented to the "normal user" has Dev Test and Dev V.03
         *   removed.  But here we need to allow the REs and Devs access to both of
         *   those projects to effectively test and add DS Rules
         */
        // Dev V.03
        if (!list.projects.find((x) => x.projectID === '56824')) {
            list.projects.unshift({ projectID: '56824', projectNumber: '003', projectName: 'Dev V.03' });
        }
        // Dev Test
        if (!list.projects.find((x) => x.projectID === '68457')) {
            list.projects.unshift({ projectID: '68457', projectNumber: '978', projectName: 'Dev Test' });
        }

        setRuleProjectList(list);
    }, [projectList]) // dependency list left blank on purpose

    /**
     * this will catch the user and the ds rules then calculate if they can
     *   edit rules or just view
     */
    useEffect(() => {
        if(!dataSourceRules || !userData) {
            return;
        }
        /**
         * @returns true if the user can edit the rules else false
         */
        const checkCanEditRules = () => {
            const userADGroups = userData.adGroups.map(({ id }) => id);
            const adGroupsCheck = userADGroups.length === 0 ? 'false' : userADGroups.reduce((acc, next) => {
                    if (acc === 'true') {
                        return acc;
                    }
                    if (dataSourceRules.canEditRules.adGroups.includes(acc)) {
                        return 'true';
                    }
                    return next;
                });
            const userProjectsCheck = userData.projects.length === 0 ? 'false' : userData.projects.reduce((acc, next) => {
                    if (acc === 'true') {
                        return acc;
                    }
                    if (dataSourceRules.canEditRules.projects.includes(acc)) {
                        return 'true';
                    }
                    return next;
                });
            return (
                dataSourceRules.canEditRules.jobCodes.some((x) => x === userData.jobCode)
                || dataSourceRules.canEditRules.users.some((x) => x === userData.email)
                || adGroupsCheck === 'true'
                || userProjectsCheck === 'true'
            );
        }


        setEditable(checkCanEditRules())
    }, [userData, dataSourceRules, setEditable])

    /**
     * used to set the current Data Source name
     * @param value Data source name
     */
    const handleDataSourceChange = async (value: string) => {
        setSelectedDataSource(value);
        let name = '';
        if (dataSourceHeaders && dataSourceHeaders.dataSources) {
            name = dataSourceHeaders.dataSources.find((header) => (header.externalId === value))?.name ?? '';
        }
        setSelectedDataSourceName(name);
    }

    /**
     * this handles when a project is selected from the dropdown by a user
     *   This is because the selected project can be manipulated on different
     *   pages of the application
     */
    useEffect(() => {
        if (headersLoading || (!userData.email)) {
            return;
        }

        const getDataSources = async (companyID?: string) => {
            if (!companyID) {
                return;
            }
            setHeadersLoading(true);
            try {
                const [rulesResponse, headerResponse] = await Promise.all([
                    DataSourceService.getAllDataSourceRules(),
                    DataService.getAllHeaders(selectedProject)
                ]);

                const headers = headerResponse.data;

                /**
                 * At some point it would be nice if the data source
                 *   names still showed up, but were un-selectable.  This is
                 *   out of scope for now, but should be added as a future
                 *   story (Story 93380)
                 */

                // If we are NOT using the primary environment we want to remove any data
                //   sources that are globaly scoped
                // currently the primary environment is Dev V.03 56824
                if (companyID !== process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT) {
                    // find the globaly scoped data sources
                    const rules = rulesResponse.data.filter(x => (
                        x.environmentID === process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT
                        && x.projectSpecific === false
                    ));

                    // filter them out of the data source list.  We need to
                    //  account for when there are no global rules to remove
                    headers.dataSources = rules.length === 0 ?  headers.dataSources : headers.dataSources.filter(datasource => (
                        !rules.some(rule => (rule.externalID === datasource.externalId))
                    ));
                }

                // if the newly selected project doesn't allow editing for the rules (i.e. global data sources not in Dev V.03)
                //   then we clear the selected datasource
                if (!headers.dataSources.some((dataSource) => dataSource.externalId === selectedDataSource)) {
                    setSelectedDataSource('');
                    setSelectedDataSourceName('');
                }

                setHeaders(headers);
                setHeadersLoading(false);

            } catch (error) {
                console.log('There was an error getting the DS info:', error)
                setSelectedDataSource('');
                setSelectedDataSourceName('');
            }
        }

        getDataSources(selectedProject);
        // exclude headersLoading, userData.email, and project to prevent reloading
        //   as page loads in other components
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    /**
     * this will grab the new data for the given project and data source when
     *   the selection changes
     */
    useEffect(() => {
        if (!selectedProject || !selectedDataSource) {
            return;
        }
        setRulesLoading(true);
        // get the data source rules details
        DataSourceService.getDataSourceRules(selectedProject, selectedDataSource)
            .then((response) => {
                let details: DataSourceRulesDetail = response.data;

                // if the segment is undefined we know the rule is not setup
                //   so we can set up a generic rule Detail here
                if (!details.segment && !details.rules) {
                    details.segment = 'both';
                    details.projectSpecific = true;
                    details.rules = JSON.stringify(generateEmptyRules());
                }
                setRulesDetails(details);

                let rules = JSON.parse(details.rules)

                setCurrentProjectSpecificValue(details.projectSpecific);
                setDataSourceRules(structuredClone(rules));
                setInitialDataSourceRules(structuredClone(rules));
            })
            .catch((e) => {
                console.log('error: ', e)
            })
            .finally (() => {
                setRulesLoading(false);
            });
    /**
     * selectedProject is not included in deps on purpose
     *   we only want this to run when the selected Data Source has changed
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataSource])

    
    /**
     * This is used to return a selector with the avaialbe Data Sources for viewing
     * @param headers the list of data sources for the company/environment
     * @returns A drop down list of available Data Sources for the user
     */
    const selectDataSourceHeaders = (headers: IDataSourceResponse) => {
        if (!headers) {
            return;
        }
        return (
            <DataSourceDropDown
                dataSourceList={headers.dataSources}
                selectionHandler={handleDataSourceChange}
                cookiesName='rulesEditor'
            />
        );
    };

    /**
     * This is a way for us to govern the behavior of the "Save" Button
     *   the purpose is to reduce any confusion that may arrise during
     *   editing as a feedback to the user on whether or not the values
     *   on screen differ from the dB.
     * NOTE: some of the checks are not _really_ needed as of yet, but in
     *   short order I would like to offer the ability to update the entirety
     *   of the rules to users and want to make sure all the bases are
     *   covered now
     * @param dsRules the rules that are updated via the user input
     * @returns true/false based on if the new rules have deviated from
     *   what is in the dB
     */
    const checkIfNewRules = (dsRules: DataSourceRules) => {
        // if either are null
        if (dsRules === null || initialDataSourceRules === null || initialDataSourceRules === undefined) { return false; }

        // if they are pointing to the same data
        if (dsRules === initialDataSourceRules) { return false; }

        // if any of the array lengths are mismatched
        if (
            (dsRules.canEditRules.adGroups.length !== initialDataSourceRules.canEditRules.adGroups.length)
            || (dsRules.canEditRules.jobCodes.length !== initialDataSourceRules.canEditRules.jobCodes.length)
            || (dsRules.canEditRules.projects.length !== initialDataSourceRules.canEditRules.projects.length)
            || (dsRules.canEditRules.users.length !== initialDataSourceRules.canEditRules.users.length)
            || (dsRules.editability.adGroups.length !== initialDataSourceRules.editability.adGroups.length)
            || (dsRules.editability.jobCodes.length !== initialDataSourceRules.editability.jobCodes.length)
            || (dsRules.editability.projects.length !== initialDataSourceRules.editability.projects.length)
            || (dsRules.editability.users.length !== initialDataSourceRules.editability.users.length)
            || (dsRules.visibility.adGroups.length !== initialDataSourceRules.visibility.adGroups.length)
            || (dsRules.visibility.jobCodes.length !== initialDataSourceRules.visibility.jobCodes.length)
            || (dsRules.visibility.projects.length !== initialDataSourceRules.visibility.projects.length)
            || (dsRules.visibility.users.length !== initialDataSourceRules.visibility.users.length)
            || (dsRules.cellValidations?.length !== initialDataSourceRules.cellValidations?.length)
        ) {
            return true;
        }
        
        // we will assume that everything is sorted, because that is the way it
        //   it is handled throughout the process of data entry and retrieval

        // brute force check if the canEditRules has changed
        for(let i = 0; i < dsRules.canEditRules.adGroups.length; ++i) {
            if (dsRules.canEditRules.adGroups[i] !== initialDataSourceRules?.canEditRules.adGroups[i]) { return true; }
        }
        for(let i = 0; i < dsRules.canEditRules.jobCodes.length; ++i) {
            if (dsRules.canEditRules.jobCodes[i] !== initialDataSourceRules?.canEditRules.jobCodes[i]) { return true; }
        }
        for(let i = 0; i < dsRules.canEditRules.projects.length; ++i) {
            if (dsRules.canEditRules.projects[i] !== initialDataSourceRules?.canEditRules.projects[i]) { return true; }
        }
        for(let i = 0; i < dsRules.canEditRules.users.length; ++i) {
            if (dsRules.canEditRules.users[i] !== initialDataSourceRules?.canEditRules.users[i]) { return true; }
        }

        for(let i = 0; i < dsRules.editability.adGroups.length; ++i) {
            if (dsRules.editability.adGroups[i] !== initialDataSourceRules?.editability.adGroups[i]) { return true; }
        }
        for(let i = 0; i < dsRules.editability.jobCodes.length; ++i) {
            if (dsRules.editability.jobCodes[i] !== initialDataSourceRules?.editability.jobCodes[i]) { return true; }
        }
        for(let i = 0; i < dsRules.editability.projects.length; ++i) {
            if (dsRules.editability.projects[i] !== initialDataSourceRules?.editability.projects[i]) { return true; }
        }
        for(let i = 0; i < dsRules.editability.users.length; ++i) {
            if (dsRules.editability.users[i] !== initialDataSourceRules?.editability.users[i]) { return true; }
        }

        for(let i = 0; i < dsRules.visibility.adGroups.length; ++i) {
            if (dsRules.visibility.adGroups[i] !== initialDataSourceRules?.visibility.adGroups[i]) { return true; }
        }
        for(let i = 0; i < dsRules.visibility.jobCodes.length; ++i) {
            if (dsRules.visibility.jobCodes[i] !== initialDataSourceRules?.visibility.jobCodes[i]) { return true; }
        }
        for(let i = 0; i < dsRules.visibility.projects.length; ++i) {
            if (dsRules.visibility.projects[i] !== initialDataSourceRules?.visibility.projects[i]) { return true; }
        }
        for(let i = 0; i < dsRules.visibility.users.length; ++i) {
            if (dsRules.visibility.users[i] !== initialDataSourceRules?.visibility.users[i]) { return true; }
        }

        if (dsRules.cellValidations !== undefined && initialDataSourceRules.cellValidations !== undefined) {
            for(let i = 0; i < dsRules.cellValidations?.length; ++i) {
                if ((dsRules.cellValidations[i].column !== initialDataSourceRules.cellValidations[i].column)
                  && (dsRules.cellValidations[i].regex !== initialDataSourceRules.cellValidations[i].regex))
                {
                    return true;
                }
            }
        }

        // finally they must be equal if everthing else is ruled out
        return false;
    }

    /**
     * handle update to the visibility permission set
     *   sets data source rules and save status
     * @param visibility
     * @returns nothing
     */
    const handleVisibilityPermissionsChange = (visibility: Permissions) => {
        if (!dataSourceRules) {
            return;
        }
        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.visibility = visibility;
        setDataSourceRules(newRules);

        if (checkIfNewRules(newRules)) {
            setSaveStatus(SaveStatus.Unsaved);
        } else {
            setSaveStatus(SaveStatus.Saved)
        }
    }

    /**
     * handle update to the editability permission set
     *   sets data source rules and save status
     * @param editability
     * @returns nothing
     */
    const handleEditabilityPermissionsChange = (editability: Permissions) => {
        if (!dataSourceRules) {
            return;
        }
        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.editability = editability;
        setDataSourceRules(newRules);

        if (checkIfNewRules(newRules)) {
            setSaveStatus(SaveStatus.Unsaved);
        } else {
            setSaveStatus(SaveStatus.Saved)
        }
    }

    /**
     * handle update to the cell validations
     *   sets data source rules and save status
     * @param validations
     * @returns nothing
     */
    const handleCellValidationChange = (validations: CellValidation[]) => {
        if (!dataSourceRules) {
            return;
        }

        const newRules: DataSourceRules = structuredClone(dataSourceRules);
        newRules.cellValidations = validations;
        setDataSourceRules(newRules);

        if (checkIfNewRules(structuredClone(newRules))) {
            setSaveStatus(SaveStatus.Unsaved);
        } else {
            setSaveStatus(SaveStatus.Saved)
        }
    };

    /**
     * add the button click to the history table in azure db
     * @param status 
     * @returns nothing
     */
    const recordSaveClick = (status: SaveStatus) => {
        if (!selectedProject || !selectedDataSource) {
            return;
        }
        let claimStatusText = '';
        switch (status) {
            case SaveStatus.Saved: claimStatusText = 'Success'; break;
            case SaveStatus.Error: claimStatusText = 'Error'; break;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, selectedDataSource, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Save,
            page: BlattnerPortalPage.RulesEditor,
            message: `result: ${claimStatusText}`,
            employeeID: userData.employeeID.toString(),
        })
        .then((result) => {
            console.log("Recording Save success in rules editor", result)
        })
        .catch((e) => {
            console.log("Error happened recording save click in rules editor", e)
        });
    };

    /**
     * This is used to do the work of actually saving the updated rules to the database
     */
    const saveRules = async () => {
        if (!rulesDetails) {
            return;
        }

        const newRulesDetail = structuredClone(rulesDetails);
        if (!newRulesDetail) {
            return;
        }

        // capture any new values that may be associated with this rule set
        // if the currentProjectSpecificValue is undefined default to true
        // this is the safest behavior
        newRulesDetail.projectSpecific = currentProjectSpecificValue === undefined ? true : currentProjectSpecificValue;
        newRulesDetail.rules = JSON.stringify(dataSourceRules);

        // save them to the state
        setRulesDetails(newRulesDetail);

        try {
            await DataSourceService.setDataSourceRules(selectedProject, selectedDataSource, newRulesDetail);
            const successMsg = (
                <div>
                    <p className='text-center' >
                        Save Successfull! Nice Work!!!!
                    </p>
                </div>
            );

            setInitialDataSourceRules(structuredClone(dataSourceRules));
            successToast(DataSourceMessage(selectedDataSoureceName, successMsg));
            setSaveStatus(SaveStatus.Saved);
            recordSaveClick(SaveStatus.Saved);
        } catch (error: any) {
            setSaveStatus(SaveStatus.Error);
            recordSaveClick(SaveStatus.Error);
            const errorMsg = (
                <div>
                    <p className='text-center' >
                        There was an issue saving the rules.
                    </p>
                    <p>
                        {error}
                    </p>
                </div>
            );
            errorToast(DataSourceMessage(selectedDataSoureceName, errorMsg));
            throw new Error('Error while saving Data Source Rules', error);
        };
    };

    /**
     * catch the Save Click on the DS Rules
     *   set save status
     */
    const handleSaveClick = () => {
        if (saveStatus === SaveStatus.Unsaved) {
            setSaveStatus(SaveStatus.Saving)
            saveRules();
        }
    };

    /**
     * catch the update to the 'project specific' rule
     *   set current projectect specific value and save status
     * @param value
     * @returns nothing
     */
    const handleRuleScopeChange = ((value: boolean) => {
        if (!rulesDetails) {
            return;
        }

        setCurrentProjectSpecificValue(value);

        if (value !== rulesDetails.projectSpecific) {
            setSaveStatus(SaveStatus.Unsaved);
        } else {
            setSaveStatus(SaveStatus.Saved)
        }
    });

    return (
        <div className='data-source-page'>

            <Modal
                className='modal-xl'
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='data-source-help-modal'>
                        <h3>
                            Rules Editor Help&nbsp;
                            <a
                                href='ruleEditor/ruleEditorHelp'
                                target='_blank'
                                onClick={() => setShowModal(false)}
                                title='open in new tab'
                            >
                                <NewTab />
                            </a>
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {RuleEditorHelp()}
                </Modal.Body>
            </Modal>

            <div className='row border-bottom mx-3'>
                <div
                    className='col-1 mt-4 pt-1 text-center data-source-help-btn'
                    onClick={() => setShowModal(true)}
                >
                    <p className='text-center'>
                        Rule Help <br/>
                        <InfoCircle size="20" />
                    </p>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                </div>
                <div className='col-3'>
                    {
                        ruleProjectList &&
                        <ProjectDropDown
                          projectList={ruleProjectList}
                          selectedProjectHandler={setSelectedProject}
                          cookieName='rulesProjects'
                        />
                    }
                </div>
                <div className='col-3'>
                    <LoadingWrapper showLoading={headersLoading}>
                        {dataSourceHeaders && selectDataSourceHeaders(dataSourceHeaders)}
                    </LoadingWrapper>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    <SaveButton
                        saveStatus={saveStatus}
                        onClick={() => handleSaveClick()}
                    />
                </div>
            </div>
            <div className='row pt-1 mt-1'>
                <div className='col'>
                    {
                        selectedDataSource &&
                        <LoadingWrapper showLoading={rulesLoading}>
                            {
                                dataSourceRules && (masterEmployeeList) &&
                                <div>
                                    <div className='mt-3'>
                                        <RuleScopeDisplay
                                            value={rulesDetails?.projectSpecific ? true : false}
                                            editable={editable}
                                            onChange={(value: boolean) => handleRuleScopeChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <PermissionDisplay
                                            title={'Visibility'}
                                            permissions={structuredClone(dataSourceRules.visibility)}
                                            editable={true}
                                            masterJobCodeList={generateJobCodeMasterList()}
                                            masterEmployeeList={masterEmployeeList}
                                            onChange={(value) => handleVisibilityPermissionsChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <PermissionDisplay
                                            title={'Editability'}
                                            permissions={structuredClone(dataSourceRules.editability)}
                                            editable={true}
                                            masterJobCodeList={generateJobCodeMasterList()}
                                            masterEmployeeList={masterEmployeeList}
                                            onChange={(value) => handleEditabilityPermissionsChange(value)}
                                        />
                                    </div>
                                    <div className='mt-3'>
                                        <CellValidationDisplay
                                            title={'Collumn Cell Validations'}
                                            validations={structuredClone(dataSourceRules.cellValidations)}
                                            editable={true}
                                            onChange={handleCellValidationChange}
                                        />
                                    </div>
                                </div>
                            }
                        </LoadingWrapper>
                    }
                </div>
            </div>
        </div>
    );
};

export default RuleEditorComponent;