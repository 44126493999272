import React, { useState, useEffect, useCallback } from 'react';
import { Container, Modal } from 'react-bootstrap';

import DataEditorHelp from './DataEditorHelp';

import ClaimDSButton from '../../components/DataSource/ClaimButton';
import DataSourceDropDown from '../../components/DataSource/DropDown';
import DataEditorTable from '../../components/DataSource/DataEditorTable'
import DataSourceMessage from '../../components/DataSource/DataSourceMessage';
import SaveDSButton from '../../components/DataSource/SaveButton';
import SaveGlobalDataSourceDisplay from '../../components/DataSource/SaveGlobalDataSourceDisplay';
import ExportToExcelButton from "../../components/ExportToExcelButton";
import InfoCircle from '../../components/Icons/InfoCircle';
import NewTab from '../../components/Icons/NewTab';
import ImportFromExcelButton from "../../components/ImportFromExcelButton";
import LoadingWrapper from '../../components/LoadingWrapper';
import { errorToast, successToast } from '../../components/Toasty';

import { BlattnerPortalPage } from '../../enums/BlattnerPortalPage';
import { DataSourceHistoryType } from '../../enums/DataSourceHistoryType';
import { DataSourceStatus } from '../../enums/DataSourceStatus';
import { RowInsertType } from '../../enums/RowInsertType';
import { SaveStatus } from '../../enums/SaveStatus';

import { convertToDS, convertToEDS } from '../../functions/DataSource';

import DataService from '../../services/DataService';
import DataSourceService from '../../services/DataSourceService';

import {
    DataSource,
    DataSourceCell,
    EditableDataSource,
    ExcelDataSource
} from '../../types/Data/DataSource';

import DataSourcesResponse from '../../types/Data/DataSourcesResponse'
import { CellValidation, DataSourceRules, Permissions } from '../../types/DataSource/DataSourceRules';
import DataSourceRulesDetail from '../../types/DataSource/DataSourceRulesDetail';
import DataSourceEmployeeInfo from '../../types/Employee/DataSourceEmployeeInfo';
import EnvironmentData from '../../types/FormsOnFire/EnvironmentData';

/**
 * @param dsRows an editable data source cell grid
 * @returns set of indicies that contain duplicate data
 */
const getDuplicateValueIndicies = (dsRows: DataSourceCell[][]): Set<number> => {
    const mySet = new Set<number>();
    const array = dsRows.map(row => (row[0]));
    for (let i = 0 ; i < array.length - 1; ++i) {
        for (let j = i + 1; j < array.length; ++j) {
            if (array[i].data === array[j].data) {
                mySet.add(i); // add the original index to the error set as well
                mySet.add(j);
            }
        }
    }

    return (mySet);
}

/**
 * check regex on a cell
 * @param data
 * @param regex
 * @returns false if the regex doesn't match, else true
 */
const checkCellRegex = (data: string, regex: string) => {
    const re = new RegExp(regex);
    return (data.match(re) !== null);
};

/**
 * check if a cell has any validation errors
 * @param data
 * @param regExes
 * @returns true if errors are present, else false
 */
const cellHasErrors = (data: string, regExes: string[]) => {
    let error = false;

    regExes.forEach((regex) => {
        if (!checkCellRegex(data, regex)) {
            error = true;
        }
    });

    return(error);
};

/**
 * manage row insert for context menu
 * @param targetRow
 * @param type
 * @param dataSource
 * @returns EditableDataSource with updated rows
 */
const getUpdatedData = (targetRow: number, type: RowInsertType, dataSource: EditableDataSource) => {
    if (!dataSource || !dataSource.rows) {
        return;
    }

    const newDS: EditableDataSource = { ...dataSource };
    const newRowIndex = (targetRow + (type === RowInsertType.Above ? 0 : 1));

    // setup the new row of data
    const newRow = dataSource.rows[targetRow].map(({
        data,
        rowIndex,
        columnIndex,
        modified,
        editable,
        visible,
        selected,
        validations,
    }) => {
        const newData = type === RowInsertType.Clone ? data : '';
        editable = true;
        const error = cellHasErrors(newData, validations) && editable;
        return ({
            data: newData,
            rowIndex: newRowIndex,
            columnIndex,
            modified: true,
            editable,
            visible,
            selected: false,
            validations,
            error,
        })
    });

    const tempRows = dataSource.rows.map((data) => data);
    // insert the data in the appropriate row
    tempRows.splice(newRowIndex, 0, newRow); // add the new row in
    // update row indicies
    const rows = tempRows.map((row, i) => row.map(({ rowIndex, ...otherData }) => ({ rowIndex: i, ...otherData })));
    newDS.rows = rows;
    return newDS;
};

/**
 * manage deletion of rows for context menu
 * @param targetRow
 * @param dataSource
 * @returns EditableDataSource with modified rows
 */
const deleteFromData = (targetRow: number, dataSource: EditableDataSource) => {
    if (!dataSource || !dataSource.rows) {
        return;
    }
    const newDS: EditableDataSource = { ...dataSource };
    // update grid
    const rows = dataSource.rows
        .filter((row) => row[0].rowIndex !== targetRow) // remove target row
        .map((row, i) => row.map(({ rowIndex, ...other}) => ({ rowIndex: i, ...other }))); // redo cell grid

    newDS.rows = rows;
    return newDS;
};

interface DataSourceComponentProps {
    globalEnvironmentList: EnvironmentData[];
    projectSelector?: React.ReactNode;
    selectedProject?: string;
    userData: DataSourceEmployeeInfo;
};

const DataSourceComponent = ({globalEnvironmentList, projectSelector, selectedProject, userData}: DataSourceComponentProps) => {
    const [project, setProject] = useState<string>('');

    const [dataSourceHeaders, setHeaders] = useState<DataSourcesResponse>();
    const [headersLoading, setHeadersLoading] = useState<boolean>(false);

    const [selectedDataSource, setSelectedDataSource] = useState<string>('');
    const [loadingDS, setLoadingDS] = useState<boolean>(false);

    const [dataSource, setDataSource] = useState<EditableDataSource>();

    // used for creating the table
    const [rulesDetails, setRulesDetails] = useState<DataSourceRulesDetail>();
    const [dataSourceRules, setDataSourceRules] = useState<DataSourceRules>();

    const [edited, setEdited] = useState<boolean>(false);
    const [claimStatus, setClaimStatus] = useState<DataSourceStatus>(DataSourceStatus.Unclaimed);
    const [canEdit, setUserCanEdit] = useState<boolean>(false);
    const [canView, setUserCanView] = useState<boolean>(false);

    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.Unsaved);
    const [tableKey, setTableKey] = useState<string>('tableKey0');
    const [saveKey, setSaveKey] = useState<string>('saveKey0');
    const [claimKey, setClaimKey] = useState<string>('claimKey0');

    const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
    const [showGlobalSaveModal, setShowGlobalSaveModal] = useState<boolean>(false);
    const [globalModalCloseable, setGlobalModalCloseable] = useState<boolean>(true);

    const setIncomingProject = (p: string) => {
        if (p === '' || project === p) {
            return;
        }
        setProject(p);
    };

    setIncomingProject(selectedProject ?? '');

    /**
     * this handles when a project is selected from the dropdown by a user
     *   This is because the selected project can be manipulated on different
     *   pages of the application
     */
    useEffect(() => {
        if (headersLoading || (!userData.email)) {
            return;
        }

        const getDataSources = async (companyID?: string) => {
            if (!companyID) {
                return;
            }
            setHeadersLoading(true);

            const rules = await DataSourceService.getAllDataSourceRules();
            const globalRules = rules.data.filter(x => (
                x.environmentID === process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT
                && x.projectSpecific === false
            ));

            await DataService.getUserDataSourceHeaders(userData.email, companyID)
                .then((response: { data: React.SetStateAction<DataSourcesResponse | undefined> }) => {
                    const headers: DataSourcesResponse = response.data as DataSourcesResponse;

                    /**
                     * eliminate displaying of rules that are globally scoped on selected projects
                     *   other than the primary environment (for now it is always Dev V.03, 56824)
                     */
                    if (companyID !== process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT) {
                        const dataSources: DataSource[] = headers.dataSources.filter((dataSource) => (
                            !globalRules.some((rule) => (rule.externalID === dataSource.externalId))
                        ))
                        headers.dataSources = dataSources;
                    }
                    setHeaders(headers);
                    setHeadersLoading(false);
                })
                .catch((error: any) => {
                    setSelectedDataSource('');
                    console.log('Fetching Data Source Headers: ', error);
                });
        }
        getDataSources(project);
        // exclude headersLoading, userData.email, and project to prevent reloading
        //   as page loads in other components
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject]);

    /**
     * used to set the current Data Source name
     * @param value Data source name
     */
    const handleDataSourceChange = async (value: string) => {
        setSelectedDataSource(value);
    }

    /**
     * This is used to return a selector with the avaialbe Data Sources for viewing
     * @param headers the list of data sources for the company/environment
     * @returns A drop down list of available Data Sources for the user
     */
    const selectDataSourceHeaders = (headers: DataSourcesResponse) => {
        if (!headers) {
            return;
        }
        return (
            <DataSourceDropDown
                dataSourceList={headers.dataSources}
                selectionHandler={handleDataSourceChange}
                cookiesName='dataSource'
            />
        );
    };

    /**
     * this is used to interrogate a Permission set and return if the current selected
     *   user is given permission by this particular permission
     * @param Permissions
     * @returns boolean if they are found in the permission set
     */
    const hasAccess = useCallback(({ users, jobCodes, adGroups, projects }: Permissions) =>{
        let adGroupMatch = false;
        const  userAdGroups = userData.adGroups.map(({ id }) => id)
        userAdGroups.forEach(id => {
            if (adGroups?.includes(id)) {
                adGroupMatch = true;
            }
        })
        let projectMatch = false;
        userData.projects?.forEach((project) => {
            if (projects?.includes(project)) {
                projectMatch = true;
            }
        })
        return ( users?.includes(userData.email)
            || jobCodes?.includes(userData.jobCode)
            || adGroupMatch
            || projectMatch
        );
        // excluding all, as loading components caused erratic behavior
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * the page will start out without the rules being available.
     *   this will handle the refresh once the rules get loaded
     */
    useEffect(() => {
        if (!dataSourceRules) {
            return;
        }
        const canView = hasAccess(dataSourceRules.visibility)
        const canEdit = hasAccess(dataSourceRules.editability)
        setUserCanEdit(canEdit);
        setUserCanView(canView);
        if (!dataSource || !dataSource.rows) {
            return;
        }
        dataSource.rows.forEach(row => {
            row.forEach(col => {
                col.visible = canView;
                col.editable = canEdit;
            })
        })

        // exclude datasource to prevent reloading as page loads in other components
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasAccess, dataSourceRules])

    /**
     * This manipulates the data source values to keep them in line with the
     *   user changes.
     * @param cell
     */
    const handleCellChange = (cell: DataSourceCell) => {
        if (cell.rowIndex === undefined || cell.columnIndex === undefined || !dataSource?.rows) {
            return;
        }
        setSaveStatus(SaveStatus.Unsaved);
        const r = cell.rowIndex;
        const c = cell.columnIndex;

        cell.error = cellHasErrors(cell.data, cell.validations) && cell.editable;
        dataSource.rows[r][c] = cell;

        saveAllDataSourceData(dataSource, c === 0);
        setEdited(dataSource.rows.flat().some((x) => (x.modified)));
    };

    /**
     * This is used to keep one spot to update the Data Source and it's key (for refressing the page)
     * @param newDataSource the updated data source to be saved to memory
     */
    const saveAllDataSourceData = (newDataSource: EditableDataSource, validateColumnZero: boolean = false) => {

        if (!newDataSource) {
            return;
        }
        let rows: DataSourceCell[][] | undefined = newDataSource.rows;

        /**
         * Here we check if the zeroeth column has any duplicates.
         *   In (all) data sources this as a unique identifier of sorts
         *   that we use to be able to identify which row we want to pull in
         *   when searching for data
         */
        if (rows && validateColumnZero) {
            const dups: Set<number> = getDuplicateValueIndicies(rows);
            rows.forEach((row, i) => {
                row[0].error = dups.has(i) || cellHasErrors(row[0].data, row[0].validations);
            })
        }
        setDataSource(newDataSource);
    };

    /**
     * This is used to make sure the insert is tracked appropriately in the data source data
     *   when everything is calculated it will call the saveAllDataSourceData function
     *   which will help the page refresh
     * @param targetRow
     * @param type
     */
    const handleRowInsert = (targetRow: number, type: RowInsertType) => {
        if (!dataSource || !dataSourceRules) {
            return;
        }
        const newDataSource = getUpdatedData(targetRow, type, dataSource);
        if (newDataSource && dataSourceRules) {
            saveAllDataSourceData(newDataSource, true);
        }

        setEdited(true);
        setSaveStatus(SaveStatus.Unsaved);
    }

    /**
     * This is used to make sure the delete is tracked appropriately in the data source data
     *   when everything is calculated it will call the saveAllDataSourceData function
     *   which will help the page refresh
     * @param targetRow
     */
    const handleRowDelete = (targetRow: number) => {
        if (!dataSource || !dataSourceRules) {
            return;
        }
        const newDataSource = deleteFromData(targetRow, dataSource);

        if (newDataSource) {
            saveAllDataSourceData(newDataSource);
        }

        setEdited(true);
        setSaveStatus(SaveStatus.Unsaved);
    }

    /**
     * this is broken out simply to make the calling useEffect code (below)
     *   a little easier to read
     * this will take new data and verify that there are no validation errors
     */
    const processDS = useCallback((rulesData: DataSourceRulesDetail, dsData: DataSource, claimed: boolean) => {
            // setup data source rules

            setRulesDetails(rulesData);
            let dsRules: DataSourceRules = JSON.parse(rulesData.rules);

            setDataSourceRules(dsRules);

            // setup data source
            const ds = convertToEDS(dsData);

            const { cellValidations } = dsRules;
            // setup validation
            cellValidations?.forEach(({ column, regex }) => {
                ds.rows.forEach((row: DataSourceCell[]) => {
                    if (row[column]) {
                        row[column].validations.push(regex);
                        row[column].error = cellHasErrors(row[column].data, row[column].validations) && row[column].editable;
                    }
                });
            });

            if (!ds || !dsRules) {
                return;
            }
            setDataSource(ds);
        // excluding saveRules, as loading components caused erratic behavior
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDataSourceRules, setDataSource]);

    /**
     * this is the function that will do the actual work of getting he datasource, rules, and claim status
     *   once they are selected by the user and save the corresponding data to the State
     * @param project the user selected project
     * @param selectedDataSource the user selected data source id
     */
    const setupEditableDataSource = useCallback(async(project: string, selectedDataSource: string) => {
        try {
            if (!project || !selectedDataSource)
            {
                return;
            }
            setLoadingDS(true);

            // get both sets of data from the API
            const [
                { status: dsStatus, data: dsData, statusText: dsStatusText },
                { status: rulesStatus, data: rulesData, statusText: rulesStatusText },
            ] = await Promise.all([
                DataService.getDataSource(project, selectedDataSource),
                DataSourceService.getDataSourceRules(project, selectedDataSource)
            ]);

            if (!dsData || !rulesData || dsStatus !== 200 || rulesStatus !== 200) {
                if (dsStatus !== 200) {
                    throw new Error (`Getting data source ds returned status: ${dsStatus} message: ${dsStatusText}`);
                }
                if (rulesStatus !== 200) {
                    throw new Error (`Getting data source rules returned status: ${rulesStatus} message: ${rulesStatusText}`);
                }
                return;
            }
            const claimed = claimStatus === DataSourceStatus.ClaimedByUser;
            processDS(rulesData, dsData, claimed);
        } catch (error) {
            console.log('Error loading page', error);
        } finally {
            setLoadingDS(false);
        };
    }, [processDS, setLoadingDS, claimStatus]);

    /**
     * this is used to trigger the loading of the Data Source data
     */
    useEffect(() => {
        if (project === undefined || selectedDataSource === undefined) {
            return;
        }
        setupEditableDataSource(project, selectedDataSource);
    }, [project, selectedDataSource, setupEditableDataSource]);

    /**
     * used to clean up the code related to storing the history of the button click
     * @param status
     * @returns nothing
     */
    const recordClaimClick = (status: DataSourceStatus) => {
        if (!selectedProject || !dataSource) {
            return;
        }
        let claimStatusText = '';
        switch (status) {
            case DataSourceStatus.Unclaimed: claimStatusText = 'Unclaimed'; break;
            case DataSourceStatus.ClaimedByUser: claimStatusText = 'Claimed by User'; break;
            case DataSourceStatus.ClaimedByOther: claimStatusText = 'Claimed by Other'; break;
            case DataSourceStatus.Error: claimStatusText = 'Error'; break;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Claim,
            message: `returned: ${claimStatusText}`,
            page: BlattnerPortalPage.DataSourceEditor,
            employeeID: userData.employeeID.toString(),
        })
    }

    /**
     * used to clean up the code related to storing the history of the button click
     * @returns nothing
     */
    const recordExportClick = () => {
        if (!selectedProject || !dataSource) {
            return;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Export,
            message: '',
            page: BlattnerPortalPage.DataSourceEditor,
            employeeID: userData.employeeID.toString(),
        })
    };

    /**
     * used to clean up the code related to storing the history of the button click
     * @returns nothing
     */
    const recordImportClick = () => {
        if (!selectedProject || !dataSource) {
            return;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Import,
            message: '',
            page: BlattnerPortalPage.DataSourceEditor,
            employeeID: userData.employeeID.toString(),
        })
    };

    /**
     * used to clean up the code related to storing the history of the button click
     * @param status
     * @returns nothing
     */
    const recordSaveClick = (status: SaveStatus) => {
        if (!selectedProject || !dataSource) {
            return;
        }
        let saveStatusText = '';
        switch (status) {
            case SaveStatus.Saved: saveStatusText = 'Success'; break;
            case SaveStatus.Error: saveStatusText = 'Error'; break;
        }
        // not awaiting on purpose: as there is no need to wait for the result
        DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
            date: Date.now(),
            eventType: DataSourceHistoryType.Save,
            message: `result: ${saveStatusText}`,
            page: BlattnerPortalPage.DataSourceEditor,
            employeeID: userData.employeeID.toString(),
        })
    }

    /**
     * This is used to centralize the actions taken when the data source button is clicked
     * @param status data source status represents if the data source is available to claim
     */
    const myCaimingClick = (status: DataSourceStatus) => {
        recordClaimClick(status);
        setClaimStatus(status);
        if (status !== DataSourceStatus.ClaimedByUser) {
            setEdited(false);
        }
    };

    /**
     * updates the visibility and editability of the cells in the table
     */
    useEffect(() => {
        if (!dataSource) {
            return;
        }
        const ds = dataSource;

        const claimed = (claimStatus === DataSourceStatus.ClaimedByUser);

        ds.rows?.forEach(row => {
            row.forEach(cell =>{
                cell.visible = canView;
                cell.editable = canEdit && claimed;
            })
        })

        setDataSource(ds);
    }, [claimStatus, dataSource, canView, canEdit]);


    /**
     * This is used during saving actions for the case when a user's claim may
     *   have expired and they haven't refreshed the page recently
     * @returns boolean for if the user still has the claim
     */
    const checkStillClaimed = async () => {
        try {
            let externalID = dataSource?.externalId;
            if (!externalID) {
                throw new Error('No Data Source Has Been Chosen');
            }
            const { data } = await DataSourceService.getDataSourceActivity(project, externalID)
            if ((data.dateCheckedIn !== 0) || (data.employeeID !== userData.employeeID.toString())) {
                return false
            }
            return true;
        } catch (error: any) {
            throw new Error('Error Getting Claim Status', error)
        };
    }

    /**
     * This is used by the save button to do the work of actually saving the Data Sourcd
     */
    const saveDS = async () => {
        try {
            if (dataSource) {
                const convertedDS = convertToDS(dataSource)
                const { status, statusText } = await DataService.setDataSource(convertedDS)
                if (status !== 200) {
                    throw new Error (`Saving returned status: ${status} message: ${statusText}`);
                }
                if (selectedDataSource === undefined) {
                    return;
                }
                await setupEditableDataSource(project, selectedDataSource);
            }
        } catch (error: any) {
            throw new Error('Error trying to save data source', error);
        };
    };

    /**
     * ########################################################################
     * NOTE: This is DEPRICATED.  It is being left in for a little while to
     *   make sure it isn't needed in the near future (2024-09-06)
     * ########################################################################
     *
     * This is used to do the work of actually saving the updated rules to the database
     */
    const saveRules = async () => {
        try {
            if (!rulesDetails || !rulesDetails.rules || !dataSource || !dataSource.externalId) {
                return;
            }
            const newRulesDetail = rulesDetails;
            newRulesDetail.rules = JSON.stringify(dataSourceRules);
            DataSourceService.setDataSourceRules(project, selectedDataSource, newRulesDetail);
        } catch (error: any) {
            throw new Error('Error while saving Data Source Rules', error);
        };
    };

    /**
     * This is used to do the actual work of storing the backup Data Source to the database
     */
    const backupDS = async () => {
        try {
            if (dataSource) {
                await DataSourceService.createDataSourceBackup(dataSource.companyId.toString(), dataSource.externalId, userData.email);
            }
        } catch (error: any) {
            throw new Error('Error trying to backup data source', error);
        };
    };

    /**
     * this is the overarching "save click" function that manages all the interactions that have
     *   to happen once the user decides to save the Data Source updates
     */
    const saveClick = () => {

        const waitForGlobalSave = async () => {
            if (!selectedProject || !dataSource) {
                return;
            }

            // not awaiting for this to return on purpose
            DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
                date: Date.now(),
                eventType: DataSourceHistoryType.Save,
                message: `Global Save Start`,
                page: BlattnerPortalPage.DataSourceEditor,
                employeeID: userData.employeeID.toString(),
            })
            setShowGlobalSaveModal(true);
        }

        let hasValidationError = dataSource?.rows?.flat().some((cell) => cell.error);
        const dsName = dataSource !== undefined ? dataSource.name : 'unknown';

        if (hasValidationError) {
            const validationErrorMsg = (
                <div>
                    <p className='text-center'>
                        Validation Error!
                    </p>
                    <i>
                        Validation errors are shown by red highlighted cells.  Please correct your error before trying to save.
                    </i>
                </div>
            );
            errorToast(DataSourceMessage(dsName, validationErrorMsg));
            recordSaveClick(SaveStatus.Error);
            setSaveStatus(SaveStatus.Error);
            return;
        }

        const waitForProjectSpecificSave = async () => {
            try {
                let saveable = await checkStillClaimed();
                if (!saveable) {
                    const errorMsg = (
                        <div className='text-center'>
                            <p>
                                Claim Lost!
                            </p>
                            <p className='toast-message-subtext'>
                                Your claim has timed out or otherwise been reverted.
                                Contact IT for support.
                            </p>
                        </div>
                    );
                    errorToast(DataSourceMessage(dsName, errorMsg));
                    recordSaveClick(SaveStatus.Error);
                    setSaveStatus(SaveStatus.Error);
                    setClaimStatus(DataSourceStatus.ClaimedByOther);
                    setClaimKey(`claimKey${Date.now()}`);
                    setEdited(false);
                    return;
                    //throw new Error('Claim Lost, contact ID for support')
                }

                await Promise.all([backupDS(), saveDS()]);
                recordSaveClick(SaveStatus.Saved);
                setSaveStatus(SaveStatus.Saved);
                const successMsg = (
                    <div>
                        <p className='text-center'>
                            Save Successfull!
                        </p>
                        <i className='toast-message-subtext'>
                            Unclaim Data Source if done with all updates.
                        </i>
                    </div>
                );
                successToast(DataSourceMessage(dsName, successMsg));
            } catch (error: any) {
                recordSaveClick(SaveStatus.Error);
                setSaveStatus(SaveStatus.Error);
                setSaveKey(`saveKey${Date.now()}`)
                const errorMsg = (
                    <div className='text-center'>
                        <p>
                            Save Error!
                        </p>
                        <p className='toast-message-subtext'>
                            Contact IT for Support.
                        </p>
                    </div>
                );
                errorToast(DataSourceMessage(dsName, errorMsg));
            }
        };
        if (rulesDetails?.projectSpecific === true) {

            waitForProjectSpecificSave();
        } else {
            waitForGlobalSave();
        }
    };

    /**
     * @returns claimed status for the current user
     */
    const isClaimed = () => (claimStatus === DataSourceStatus.ClaimedByUser);

    /**
     * This is meant to handle when the user uploads a data source from an excel file
     * @param excelDataSource
     */
    const getUploadedDataSource = (excelDataSource: ExcelDataSource) => {
        if (!dataSource) {
            return;
        }
        // Verify that the row counts match
        if (dataSource.headers.length !== excelDataSource.headers.length) {
            const errorMsg = (
                <div className='text-center'>
                    <p>
                        Column Count Miss Match!
                    </p>
                    <p className='toast-message-subtext'>
                        Columns may not be added or removed using this mechanism!
                    </p>
                    <p className='toast-message-subtext'>
                        Please contact IT to modify column count of a Data Source.
                    </p>
                </div>
            );
            errorToast(DataSourceMessage(dataSource.name, errorMsg));
            return;
        }
        let headerError = false;
        let headerErrorList: number[] = [];
        let headerErrorTextList: String[] = [];
        dataSource.headers.forEach((h, i) => {
            if (h.name !== excelDataSource.headers[i].name) {
                headerError = true;
                headerErrorList.push(i);
                headerErrorTextList.push(excelDataSource.headers[i].name);
            }
        });

        const isPluralError = () => (headerErrorList.length === 1 ? '' : 's');

        if (headerError) {
            const errorMsg = (
                <div>
                    <p className='text-center'>
                        Column Header Miss Match!
                    </p>
                    <p className='toast-message-subtext'>
                        Column header{isPluralError()} cannot be changed from this page!
                        In order to import the Excel file please fix the following error{isPluralError()}.
                    </p>
                    <div>
                        <div>
                            {headerErrorList.map((x, i) => (
                                <p className='toast-message-subtext' key={`${x}_${i}`}>
                                    {`Cell ${String.fromCharCode(65 + x)}1: '${headerErrorTextList[i]}' should be '${dataSource.headers[x].name}'`}
                                </p>
                            ))}
                        </div>
                    </div>

                </div>
            );
            errorToast(DataSourceMessage(dataSource.name, errorMsg));
            return
        }
        let ds = {...dataSource}
        ds.headers = excelDataSource.headers;
        ds.rows = excelDataSource.rows;
        ds.rows.forEach(row => {
            row.forEach(col => {
                if (typeof(col.data) === 'number') {
                    let x: number = col.data
                    col.data = x.toString();
                }
                col.visible = canView;
                col.editable = canEdit;
            })
        })

        // setup validation
        if (dataSourceRules  && dataSourceRules.cellValidations) {
            dataSourceRules.cellValidations.forEach(({ column, regex }: CellValidation) => {
                if (ds.rows) {
                    ds.rows.forEach((row: DataSourceCell[]) => {
                        if (row[column]) {
                            row[column].validations.push(regex);
                            row[column].error = cellHasErrors(row[column].data, row[column].validations) && row[column].editable;
                        }
                    });
                }
            });
        }

        setSaveStatus(SaveStatus.Unsaved);
        saveAllDataSourceData(ds, true)
        setEdited(true);
    };

    return (
        <div className='data-source-page'>
            <Modal
                className='modal-xl'
                show={showHelpModal}
                onHide={() => setShowHelpModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='data-source-help-modal'>
                        <h3>
                            Data Source Editor Help&nbsp;
                            <a
                                href='dataEditor/dataEditorHelp'
                                target='_blank'
                                onClick={() => setShowHelpModal(false)}
                                title='open in new tab'
                            >
                                <NewTab />
                            </a>
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {DataEditorHelp()}
                </Modal.Body>
            </Modal>
            <Modal
                className='modal'
                show={showGlobalSaveModal}
                onHide={() => {
                    setShowGlobalSaveModal(false);
                    // if the modal is closed we can set the button back to
                    //  "Save"
                    setEdited(true);
                    if(dataSource && dataSource.rows && dataSource.rows.flat().some((x) => (x.modified))) {
                        // if there have been any changes make sure the button is visible
                        setSaveStatus(SaveStatus.Unsaved)
                    } else {
                        setSaveStatus(SaveStatus.Saved)
                    }

                    setSaveKey(`saveKey${Date.now()}`)
                    setTableKey(`tableKey${Date.now()}`);
                }}
                centered
                backdrop='static'
            >
                <Modal.Header closeButton={globalModalCloseable}>
                    <Modal.Title className='data-source-help-modal'>
                        <Container>
                            <div className='text-center ps-5'>
                                <h3>
                                    Save To All Environments
                                </h3>
                            </div>
                        </Container>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        dataSource &&
                        <SaveGlobalDataSourceDisplay
                            userData={userData}
                            environmentsToUpdate={globalEnvironmentList}
                            onSaveStatusChange={(state: SaveStatus) => {
                                if (state === SaveStatus.Saving || state === SaveStatus.Error) {
                                    setGlobalModalCloseable(false);
                                } else {
                                    setGlobalModalCloseable(true);

                                }
                                if (state === SaveStatus.Saved && selectedProject !== undefined && dataSource !== undefined) {
                                    // not awaiting for this to return on purpose
                                    DataSourceService.addDataSourceHistory(selectedProject, dataSource.externalId, {
                                        date: Date.now(),
                                        eventType: DataSourceHistoryType.Save,
                                        message: `Global Save End`,
                                        page: BlattnerPortalPage.DataSourceEditor,
                                        employeeID: userData.employeeID.toString(),
                                    })
                                    setSaveStatus(SaveStatus.Saved);

                                    const ds = dataSource;
                                    ds.rows?.forEach((row) => {
                                        row.forEach((cell) => {
                                            cell.modified = false
                                        })
                                    })
                                    setDataSource(ds);
                                    setEdited(false);
                                    saveAllDataSourceData(dataSource, true)
                                }
                            }}
                            editableDataSource={dataSource}
                        />
                    }
                </Modal.Body>
            </Modal>
            <div className='row border-bottom mx-3'>
                <div
                    className='col-1 mt-4 pt-1 text-center data-source-help-btn'
                    onClick={() => setShowHelpModal(true)}
                >
                    <p className='text-center'>
                        Editor Help <br/>
                        <InfoCircle size="20" />
                    </p>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                </div>
                <div className='col-3'>
                    {projectSelector}
                </div>
                <div className='col-3'>
                    <LoadingWrapper showLoading={headersLoading}>
                        {dataSourceHeaders && selectDataSourceHeaders(dataSourceHeaders)}
                    </LoadingWrapper>
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {(dataSource) &&
                        <ClaimDSButton
                            key={claimKey}
                            user={userData}
                            dataSourceName={dataSource.name}
                            environmentId={dataSource ? dataSource.companyId.toString() : ''}
                            externalId={dataSource ? dataSource?.externalId : ''}
                            onClick={myCaimingClick}
                            reportInitialStatus={(status) => setClaimStatus(status)}
                        />
                    }
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {(edited) &&
                        <SaveDSButton
                            key={saveKey}
                            saveStatus={saveStatus}
                            onClick={saveClick}
                        />
                    }
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {(dataSource) &&
                            <ExportToExcelButton
                                ds={dataSource}
                                onClick={() => {
                                    recordExportClick();
                                }}
                            />
                    }
                </div>
                <div className='col-1 mt-4 pt-1 d-flex justify-content-center align-items-center'>
                    {(dataSource) && (isClaimed()) && (canEdit) &&
                        <ImportFromExcelButton onUpload={(cells) => {
                                getUploadedDataSource(cells);
                                recordImportClick();
                            }}
                        />
                    }
                </div>
            </div>
            <div className='row'>
                <div className='overflow-auto h-auto'>
                    <LoadingWrapper showLoading={loadingDS}>
                        <div className='row mx-2 mt-1'>
                            {(dataSource) &&
                                <DataEditorTable
                                    key={tableKey}
                                    dataSource={dataSource}
                                    handleCellChange={handleCellChange}
                                    handleRowInsert={handleRowInsert}
                                    handleRowDelete={handleRowDelete}
                                />
                            }
                        </div>
                    </LoadingWrapper>
                </div>
            </div>
        </div>
    );
};

export default DataSourceComponent;
